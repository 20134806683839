<template>
  <div class="grid grid-cols-3 gap-x-0 lg:gap-x-10 my-16">
    <PaymentModal
      v-if="modalVisibility"
      @close="closeModal"
      @submit="submitOrder"
    />
    <div class="col-span-full flex-col items-start flex lg:col-span-2">
      <!-- <div class="text-size-20">{{ content.whiteLabelTitle }}</div> -->
      <div class="col-span-2 w-full md:col-span-1">
        <!-- <div class="order-card">
          <label>
            <input
              type="radio"
              :value="pickup ? false : true"
              :checked="pickup ? true : false"
              @change="(event) => setPickup(event)"
            />
            <span class="ms-2"> {{ content.pickup }} </span>
          </label>
          <div
            v-if="pickup"
            class="grid grid-cols-2 gap-x-10 gap-y-5 w-full mt-5 ps-5"
          >
            <div class="col-span-2 md:col-span-1">
              <div class="flex flex-col sm:flex-row">
                <label
                  for="city"
                  class="input-label mb-2 sm:mb-0 flex items-center"
                >
                  {{ content.city }}
                </label>
                <select id="city" class="form-input bg-white" v-model="cityId">
                  <option disabled selected :value="null">City</option>
                  <option
                    v-for="city in cities"
                    :key="city.id"
                    :value="city.id"
                  >
                    {{ city.name }}
                  </option>
                </select>
              </div>
              <p class="error text-sm mt-2">
                {{ errors?.city }}
              </p>
            </div>
            <div class="col-span-2 md:col-span-1">
              <div class="flex flex-col sm:flex-row">
                <label
                  for="point"
                  class="input-label mb-2 sm:mb-0 flex items-center"
                >
                  {{ content.location }}
                </label>
                <select
                  id="point"
                  class="form-input bg-white"
                  v-model="sellingPoint"
                >
                  <option
                    v-if="sellingPoints.length < 1"
                    disabled
                    selected
                    :value="null"
                  >
                    {{ content.noDrop }}
                  </option>
                  <option v-else disabled selected :value="null">
                    {{ content.location }}
                  </option>
                  <option
                    v-for="location in sellingPoints"
                    :key="location.id"
                    :value="location.id"
                  >
                    {{ location.title }}
                  </option>
                </select>
              </div>
              <p class="error text-sm mt-2">
                {{ errors?.sellingPoint }}
              </p>
            </div>
            <div class="col-span-2">
              <div class="flex flex-col sm:flex-row mt-8">
                <label
                  for="notes"
                  class="input-label mb-2 sm:mb-0 flex items-start"
                >
                  {{ content.notes }}
                </label>
                <textarea
                  type="text"
                  class="form-input"
                  v-model="notes"
                  id="notes"
                  :placeholder="content.notes"
                />
              </div>
            </div>
          </div>
        </div> -->
        <div class="order-card">
          <label>
            <!-- <input
              type="radio"
              :value="delivery ? false : true"
              :checked="delivery ? true : false"
              @change="(event) => setDelivery(event)"
            />
            <span class="ms-2"> {{ content.delivery }} </span> -->
            <span class="ms-5"> {{ content.delivery }} </span>
          </label>
          <div
            v-if="delivery"
            class="grid grid-cols-2 gap-x-10 gap-y-5 w-full mt-5 ps-5"
          >
            <div class="col-span-2 md:col-span-1">
              <div class="flex flex-col sm:flex-row">
                <label
                  for="city"
                  class="input-label mb-2 sm:mb-0 flex items-center"
                >
                  {{ content.city }}
                </label>
                <div class="relative w-full">
                  <select
                    id="city"
                    class="form-input bg-white"
                    v-model="cityId"
                  >
                    <option disabled selected :value="null">City</option>
                    <option
                      v-for="city in cities"
                      :key="city.id"
                      :value="city.id"
                    >
                      {{ city.name }}
                    </option>
                  </select>
                  <span class="error text-sm absolute left-0 top-12">
                    {{ errors?.city }}
                  </span>
                </div>
              </div>
            </div>
            <div class="col-span-2 md:col-span-1">
              <div class="flex flex-col sm:flex-row">
                <label
                  for="address"
                  class="input-label mb-2 sm:mb-0 flex items-center"
                >
                  {{ content.address }}
                </label>
                <div class="relative w-full">
                  <input
                    type="text"
                    class="form-input"
                    v-model="address"
                    id="address"
                    :placeholder="content.address"
                  />
                  <span class="error text-sm absolute left-0 top-12">
                    {{ errors?.address }}
                  </span>
                </div>
              </div>
            </div>
            <div class="col-span-2">
              <div class="flex flex-col sm:flex-row mt-8">
                <label
                  for="notes"
                  class="input-label mb-2 sm:mb-0 flex items-start"
                >
                  {{ content.notes }}
                </label>
                <textarea
                  type="text"
                  class="form-input"
                  v-model="notes"
                  id="notes"
                  :placeholder="content.notes"
                />
              </div>
            </div>
          </div>
        </div>
        <div v-if="option" class="col-span-12 lg:col-span-8 mb-4">
          <Alert type="info" :message="content.chooseShipping"> </Alert>
        </div>
      </div>
    </div>
    <div class="col-span-full lg:col-span-1">
      <OrderSummery
        :containerText="size.title"
        :containerPrice="numberFormat(price)"
        :colorText="color.title"
        :wickText="wick.title"
        :wickPrice="numberFormat(wick.price)"
        :scentText="scent.title"
        :totalPrice="numberFormat(totalPrice)"
        :quantity="numberFormat(1)"
        :totalPerItem="numberFormat(pricePerItem)"
        :delivery="delivery"
        :city="city"
      />
    </div>
    <div class="col-span-full flex justify-center items-center my-8">
      <button class="prev-tab-button me-3" @click="prevTab">
        {{ content.back }}
      </button>

      <!-- class="next-tab-button hover:text-bluePrimary hover:bg-blueSecondary lora-bold whitespace-nowrap bg-bluePrimary text-white duration-300 me-3 cursor-default" -->
      <button
        v-if="buttonDisabled"
        class="header-btn bg-cancelled bg-opacity-10 text-cancelled border-cancelled border-1 lora-bold whitespace-nowrap duration-300 me-3 cursor-default"
      >
        {{ content.order }}
      </button>

      <button
        class="next-tab-button hover:text-bluePrimary hover:bg-blueSecondary lora-bold whitespace-nowrap bg-bluePrimary text-white duration-300 me-3"
        v-else-if="user"
        @click="openModal"
      >
        {{ content.order }}
      </button>
      <span
        v-if="addingToCart || addedToCart"
        class="header-btn bg-cancelled bg-opacity-10 text-cancelled border-cancelled border-1 lora-bold whitespace-nowrap duration-300"
        disabled
      >
        {{
          addingToCart
            ? content.addingToCart
            : addedToCart
            ? content.addedToCart
            : content.addToCart
        }}
      </span>
      <button
        v-else
        class="header-btn hover:text-white text-borderColor border-borderColor border-1 hover:border-borderColor lora-bold whitespace-nowrap hover:bg-borderColor duration-300"
        @click="addToCart"
      >
        {{ content.addToCart }}
      </button>
    </div>
  </div>
</template>

<script>
import { format } from "../../assets/numberFormat";
import OrderSummery from "/src/components/Refill/OrderSummery.vue";

import PaymentModal from "../ui/PaymentModal.vue";

export default {
  components: { OrderSummery, PaymentModal },
  inject: ["content"],
  props: ["request", "buttonDisabled"],
  emits: ["prevTab", "submitOrder"],
  data() {
    return {
      modalVisibility: false,
      option: false,
      pickup: false,
      // delivery: false,
      delivery: true,
      cityId: null,
      sellingPoint: null,
      address: "",
      notes: "",
      errors: {
        city: null,
        sellingPoint: null,
        address: "",
      },
      addingToCart: false,
      addedToCart: false,
    };
  },
  watch: {
    address() {
      if (this.address != "") this.errors.address = null;
    },
    sellingPoint() {
      if (this.sellingPoint) this.errors.sellingPoint = null;
    },
  },
  computed: {
    auth() {
      return this.$store.getters["auth/auth"];
    },
    user() {
      return this.$store.getters["user/user"];
    },
    cities() {
      return this.$store.getters["cities/cities"];
    },
    city() {
      return this.cityId
        ? this.$store.getters["cities/city"](this.cityId)
        : null;
    },
    studio() {
      return this.$store.getters["shops/studio"];
    },
    sellingPoints() {
      let locations = [
        ...this.$store.getters["shops/sellingPoints"],
        this.studio,
      ];
      return this.cityId
        ? locations.filter(
            (location) => location.city.id == this.cityId && location.drop
          )
        : [];
    },
    size() {
      return this.$store.getters["materials/size"](this.request.size);
    },
    scent() {
      return this.$store.getters["materials/scent"](this.request.scent);
    },
    color() {
      return this.$store.getters["materials/color"](this.request.color);
    },
    wick() {
      return this.$store.getters["materials/wick"](this.request.wick);
    },
    container() {
      return this.$store.getters["materials/refillContainer"](
        this.request.container
      );
    },
    price() {
      return (
        (parseFloat(this.size.value) / 100) * parseFloat(this.color.refill)
      );
    },
    pricePerItem() {
      return this.price + parseFloat(this.wick.price);
    },
    formatPrice(price) {
      return format(price);
    },
    totalPrice() {
      let total = this.pricePerItem;
      if (this.delivery && this.city) total += parseFloat(this.city.price);
      return total;
    },
  },
  methods: {
    closeModal() {
      this.modalVisibility = false;
    },
    openModal() {
      if (!this.delivery && !this.pickup) {
        this.option = true;
        return;
      }

      if (!this.validation()) return;

      this.modalVisibility = true;
    },
    numberFormat(price) {
      return format(price);
    },
    setDelivery(event) {
      if (!this.city) this.city = this.user?.city?.id ?? null;
      this.delivery = event.target.value;
      if (this.pickup == this.delivery) this.pickup = !this.pickup;
      this.option = false;
    },
    setPickup(event) {
      if (!this.city) this.city = this.user?.city?.id ?? null;
      this.pickup = event.target.value;
      if (this.delivery == this.pickup) this.delivery = !this.delivery;
      this.option = false;
    },
    validation() {
      let validation = true;

      if (!this.cityId) {
        this.errors.city = this.content.chooseCity;
        validation = false;
      }

      if (this.delivery && this.address == "") {
        this.errors.address = this.content.enterAddress;
        validation = false;
      } else if (this.pickup && !this.sellingPoint) {
        this.errors.sellingPoint = this.content.chooseLocation;
        validation = false;
      }

      return validation;
    },
    async submitOrder(method) {
      this.closeModal();
      console.log(this.notes);
      
      this.$emit("submitOrder", {
        option: this.option,
        pickup: this.pickup,
        delivery: this.delivery,
        cityId: this.cityId,
        sellingPoint: this.sellingPoint,
        address: this.address,
        notes: this.notes,
        method: method,
      });
    },
    prevTab() {
      this.$emit("prevTab");
    },
    async addToCart() {
      this.addingToCart = true;

      try {
        if (this.auth) {
          console.log({
            quantity: this.request?.quantity ?? 1,
            wick: this.wick.id,
            size: this.size.id,
            scent: this.scent.id,
            color: this.color.id,
            container: this.container.id,
          });
          await this.$store.dispatch("refill/add", {
            quantity: this.request?.quantity ?? 1,
            wick: this.wick.id,
            size: this.size.id,
            scent: this.scent.id,
            color: this.color.id,
            container: this.container.id,
          });
        } else {
          this.$store.dispatch("refill/addLocal", {
            quantity: this.request?.quantity ?? 1,
            wick: this.wick,
            size: this.size,
            scent: this.scent,
            color: this.color,
            container: this.container,
          });
        }

        this.addingToCart = false;
        this.addedToCart = true;
        this.$store.dispatch("feedback/setFeedback", {
          type: "success",
          title: this.content.success,
          message: this.content.addToCartSuccess,
        });

        setTimeout(() => {
          this.addedToCart = false;
        }, 6000);
      } catch (error) {
        this.addingToCart = false;
        this.addedToCart = false;
        this.error = true;
        console.log(error);
        this.$store.dispatch("feedback/setFeedback", {
          type: "fail",
          title: this.content.fail,
          message: this.content.addToCartFail,
        });
      }
    },
  },
};
</script>
