<template>
  <div class="request-page-title layout-padding text-center my-16">
    <div class="text-size-24">{{ content.requestRefill }}</div>
    <div class="mt-2">{{ content.orderWhiteLabelCandlesContent }}</div>
  </div>
  <div class="stepper-container">
    <RequestTabs :currentTab="currentTab" />
    <div class="grid grid-cols-11">
      <div class="col-span-9 lg:col-span-7 col-start-2 lg:col-start-3 mt-8">
        <transition name="fade">
          <Container
            v-if="currentTab == 1"
            :request="request"
            :errors="errors"
            @setContainer="setContainer"
            @setSize="setSize"
          />
          <Scent
            v-else-if="currentTab == 2"
            :scent="request.scent"
            :error="errors.scent"
            @setScent="setScent"
          />
          <Wick
            v-else-if="currentTab == 3"
            :wick="request.wick"
            :error="errors.wick"
            @setWick="setWick"
          />
          <Color
            v-else-if="currentTab == 4"
            :color="request.color"
            :error="errors.color"
            @setColor="setColor"
          />
          <Summary
            v-else-if="currentTab == 5"
            :request="request"
            @prevTab="prevTab"
            @submitOrder="submitOrder"
            :button-disabled="buttonDisabled"
          />
        </transition>
        <div class="w-full flex justify-center">
          <Alert
            v-if="error"
            customClasses="max-w-550"
            type="fail"
            :title="content.fail"
            :message="content.quoteFail"
          >
            <router-link
              to="/"
              class="alert-btn text-text-white text-white lora-bold whitespace-nowrap bg-secondaryColor duration-300 mt-6"
            >
              {{ content.goHome }}
            </router-link>
          </Alert>
        </div>
      </div>
    </div>
    <div class="flex justify-center items-center my-8" v-if="currentTab < 5">
      <button class="prev-tab-button me-3" @click="prevTab">
        {{ content.back }}
      </button>
      <button
        class="next-tab-button hover:text-bluePrimary hover:bg-blueSecondary lora-bold whitespace-nowrap bg-bluePrimary text-white duration-300"
        @click="nextTab"
        v-if="currentTab < 5"
      >
        {{ content.next }}
      </button>
      <button
        class="next-tab-button hover:text-bluePrimary hover:bg-blueSecondary lora-bold whitespace-nowrap bg-bluePrimary text-white duration-300"
        @click="order"
        v-else
      >
        {{ content.order }}
      </button>
    </div>
  </div>
</template>

<script>
import RequestTabs from "/src/components/Refill/RequestTabs.vue";
import Container from "/src/components/Refill/Container.vue";
import Scent from "/src/components/Refill/Scent.vue";
import Wick from "/src/components/Refill/Wick.vue";
import Color from "/src/components/Refill/Color.vue";
import Summary from "/src/components/Refill/Summary.vue";

export default {
  data() {
    return {
      currentTab: 1,
      success: false,
      error: null,
      request: {
        container: null,
        size: null,
        color: null,
        scent: null,
        wick: null,
      },
      errors: {
        container: null,
        size: null,
        scent: null,
        wick: null,
        color: null,
      },
      buttonDisabled: false,
    };
  },
  components: {
    RequestTabs,
    Container,
    Scent,
    Wick,
    Color,
    Summary,
  },
  inject: ["content"],
  methods: {
    nextTab() {
      switch (this.currentTab) {
        case 1:
          if (this.request.container == null) {
            this.errors.container = this.content.chooseOption;
            return;
          }
          if (this.request.size == null) {
            this.errors.size = this.content.chooseOption;
            return;
          }
          // if (
          //   (this.request.container || this.request.container == false) &&
          //   this.request.size
          // )
          this.next();
          break;
        case 2:
          if (!this.request.scent) {
            this.errors.scent = this.content.chooseOption;
            return;
          }
          this.next();
          break;
        case 3:
          if (!this.request.wick) {
            this.errors.wick = this.content.chooseOption;
            return;
          }
          this.next();
          break;
        case 4:
          if (!this.request.color) {
            this.errors.color = this.content.chooseOption;
            return;
          }
          this.next();
          break;
        default:
          break;
      }
    },
    prevTab() {
      if (this.currentTab > 1) {
        this.currentTab = this.currentTab - 1;
      }
      if (this.error) this.error = null;
    },
    next() {
      if (this.currentTab < 5) {
        this.currentTab = this.currentTab + 1;
      }
    },
    setContainer(value) {
      this.request.container = value;
      this.errors.container = null;
    },
    setSize(value) {
      this.request.size = value;
      this.errors.size = null;
    },
    setScent(value) {
      this.request.scent = value;
      this.errors.scent = null;
    },
    setWick(value) {
      this.request.wick = value;
      this.errors.wick = null;
    },
    setColor(value) {
      this.request.color = value;
      this.errors.color = null;
    },
    async submitOrder(details) {
      let input = this.request;
      this.buttonDisabled = true;

      if (details.method) input.method = details.method;

      if (details.delivery) {
        input.address = details.address;
        input.city = details.cityId;
        input.notes = details.notes;
        input.sellingPoint = null;
      } else if (details.pickup) {
        input.sellingPoint = details.sellingPoint;
        input.address = null;
        input.city = null;
      }

      try {
        await this.$store.dispatch("refill/request", input);
        await this.$store.dispatch("feedback/setFeedback", {
          type: "success",
          title: this.content.success,
          message: this.content.orderSuccess,
        });
        this.buttonDisabled = false;
        if (details.method !== "fib") {
          this.$router.replace("/");
        }
      } catch (error) {
        this.buttonDisabled = false;
        this.$store.dispatch("feedback/setFeedback", {
          type: "fail",
          title: this.content.fail,
          message: this.content.quoteFail,
        });
        // this.error = true;
      }
      this.buttonDisabled = false;
    },
  },
};
</script>
